.container{
    padding: 0 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;

}
.container>img{
    position: absolute;
    width: 8rem;
    left: 20%;
    top: -3rem;
}
.menu{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-weight: 500;
    font-size: 1rem;
}
.menu>li:hover{
    color: var(--pink);
    cursor: pointer;
}
.list{
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(200px , 1fr));
    height: 25rem;
    overflow-y: scroll;
    grid-gap: 2rem;
    justify-content: space-between;
}
.products{
    width: 90%;
    display: grid;
    grid-template-columns: 25% auto;

}
.product{
    width: 10rem;
    height: 8rem;
    background-color: white;
    position: relative;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    border-radius: 1rem;
}
@media screen and (max-width:856px) {
    .container{
        gap: 1rem;
    }
    .container>:first-child{
        display: none;
    }
    .products{
        grid-template-columns: none;
        gap: 1rem;
    }
    .menu{
        flex-direction: row;
    }
}
@media screen and (max-width : 640px) {
     
    .container>:nth-child(2){
        text-align: center;
    }
    .products{
        width: 50%;
        justify-content: center;
        align-items: center;
    }
   .product{
    
    margin-left: auto;
    margin-right: auto;
   }
   .list{
    margin-left: auto;
    margin-right: auto;
    width: 70%;
   }
   
}