.footerWrapper{
    margin-top: 5rem;
    width: 100%;

}
.footerWrapper>hr{
    height: 1px;
    background: white;
    width: 100%;
    border: none;
}
.footer{
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.logo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}
.logo>img{
    width: 5rem;
    height: 5rem;
}
.logo>span{
    font-weight: 600;
}
.block{
    display: flex;
    flex-direction: column;
    gap : 1rem;
    max-width: 12rem;
}
.detail { 
    display: flex;
    flex-direction: column;
    width: inherit;
    font-size: .7rem;
    gap: 1rem;
}
.detail>:first-child{
    font-size: 1.2rem;
    font-weight: 500;
    font-style: normal;
    
}
.pngLine:hover{
    cursor: pointer;
}
.pngLine{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.icon{
    width: 25px;

}
.copyright{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    font-size: .65rem;
    margin-top: 2rem;
}

@media screen and (max-width: 768px) {
   
    .footer{
        
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 2rem;
        font-size: 1.7rem;
    }
}
@media screen and (max-width: 640px) {
   
   .footer{
    justify-content: center;
   }
    .logo{
        display: none;
    }
}