.container{
    padding: 1.5rem;
    display: flex;
}
.logo{

    display: flex;
    align-items: center;
    flex: 1;
    gap: 1rem;
}
.logo>img{
    width: 2.5rem;
    height: 2.5rem;
}
.right{
    display : flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}
.menu{
    display : flex;
    gap :2rem;
    font-weight: 500;
    list-style: none;
}
.menu>li:hover{
    color : #fe956f;
    cursor: pointer;
}
.search {
    width: 7rem;
    outline: none;
    height: 40%;

    border: none;
    border-radius: 5px;
    padding: 0.5rem;
}
.cart{
    width: 1.5rem;
    height: 1.5em;
    cursor: pointer;
    
}
.bar{
    display: none;
}
@media screen and (max-width : 640px) {
    .right{
        position: absolute;
        right: 2rem;
        z-index: 9999;
        background: white;
        color: var(--black);
        border-radius: 5px;
        padding: 1rem;
        flex-direction: column;
    } 
   .menu{
    flex-direction: column;
    margin-left: -2rem;
    display: none;
   }
   .bar{
    display: block;
   }
   .search{
    display: none;
   }
}
