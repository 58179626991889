.testimonials{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
    
}
.wrapper{
   display: grid;
   align-items: flex-end;
   width: 100%;
   grid-template-columns: 1fr 2fr 1fr;
    
}
.wrapper>img{
    width: 25rem;
    justify-self: center;
}
.container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-transform: uppercase;
}
.container>:first-child{
    font-weight: bold;
    display: block;
    font-size: 2rem;
}
.container>:nth-child(2){
    font-size: 0.8rem;
    display: block;
}
.wrapper>:nth-child(3){
    text-align: right;
}
.review{

    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
}
.carousel{
    width: 100%;
}
.carousel2{
    padding: 4rem;
}
.testimonial{
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: .5rem;
    padding: .7rem;
    gap: .7rem;
    box-shadow: 0rem 1rem 3rem -50px #7d7d7d;
    
}
.testimonial>hr{
    height: 1px;
    border: none;
    background: rgb(198, 198, 198);
    width:80%;
}
.testimonial>img{
    position: absolute;
     top: -1.5rem;   
     width: 3rem;
     left: 45%;
   
}
.testimonial>:nth-child(2){
    align-items: center;
    font-size: .8rem;
    margin-top: 2rem;
}
.testimonial>:nth-child(4){
   font-weight: bold;
}
@media screen and (max-width : 640px) {
    
    .wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 1rem;
        margin-bottom: 2rem;
    }
    .container>:first-child{
        text-align: center;
    }
    .carousel2{
        padding: 2rem;
        margin-top: 2rem;
    }
    
}